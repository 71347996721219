<template>
  <div class="container-fluid pb-5">
    <div class="page-header mb-1">
      <strong>รายงานการเงินสมาชิก</strong>
    </div>
    <div class="alert alert-info">
      <h4 class="mb-0">รหัสผู้ใช้ :::
        <span class="text-primary">{{username}}</span>
        <span v-if="fullname" class="text-secondary ml-2">({{fullname}})</span>
      </h4>
    </div>
    <AccountFinanceReport :accountId="accountId" @data="setData" />
  </div>
</template>
<script>
import AccountFinanceReport from '@/views/account-report/components/AccountFinanceReport'

export default {
  name: 'FinanceMember',
  components: {
    AccountFinanceReport
  },
  data() {
    return {
      data: null
    }
  },
  computed: {
    accountId() {
      return this.$route.params?.accountId
    },
    username() {
      if(!this.data)
        return ''

      return this.data?.account?.username
    },
    fullname() {
      if(!this.data)
        return ''

      return `${this.data?.account?.firstName} ${this.data?.account?.lastName}`.trim()
    }
  },
  methods: {
    setData(data) {
      this.data = data
    }
  }
}
</script>
